import { axios, API_ROUTES } from "@/config/index.js";

export default {
  async get(clientId) {
    let result = await axios.get(`${API_ROUTES.loans.get}/${clientId}`);

    return result;
  },

  async save(data) {
    let result = await axios.post(`${API_ROUTES.loans.save}`, data);

    return result;
  },

  async getDocuments(clientId) {
    let result = await axios.get(`${API_ROUTES.loanDocuments.get}/${clientId}`);

    return result;
  },

  async uploadDocument(data) {
    let result = await axios.post(`${API_ROUTES.loanDocuments.save}`, data);

    return result;
  },

  async deleteDocument(id) {
    let result = await axios.delete(`${API_ROUTES.loanDocuments.delete}/${id}`);

    return result;
  }
};
